import Axios from "axios";
import {isAndroid} from 'react-device-detect';
import Cookies from 'js-cookie';

import {PUBLICATION_API_URL, COOKIE_APP_NAME} from '../constants'
import PublicationUtil from '../components/util/PublicationUtil.js'

class PublicationService {

    /***** API Calls To Backend *****/

    getMostRecentPublication(){
        return Axios.get(`${PublicationUtil.getHostName() + PUBLICATION_API_URL}/${this.checkDevice()}`, {withCredentials: true});
    }

    getPublicationsPerPage(page, limit){
        return Axios.get(`${PublicationUtil.getHostName() + PUBLICATION_API_URL}/${this.checkDevice()}/${page}/${limit}`, {withCredentials: true});
    }

    searchPublication(publicationId, searchTerm, limit, page){
        let epubName = this.checkDevice();
        return Axios.post(`${PublicationUtil.getHostName() + PUBLICATION_API_URL}/search`, {publicationId, epubName, searchTerm, page, limit}, {withCredentials: true});
    }

    checkDevice(){
        let app = Cookies.get(`${COOKIE_APP_NAME}`);

        if(app !== undefined){
            if(isAndroid){
                return "android";
            } else {
                return "ios"
            }
        }
        return "web";
    }

}

export default new PublicationService();

