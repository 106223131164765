/** Api Context **/
export const NDC_PORTAL_WS_CONTEXT = "/ndc-ws";

//export const NDC_PORTAL_PORT = `8080`;
export const NDC_PORTAL_PORT = `${process.env.REACT_APP_PORT}`;

/** Authentication Settings **/
export const AUTH_API_URL = `${NDC_PORTAL_WS_CONTEXT}/authenticate`;
export const AUTH_PREFIX = "Bearer ";

/** Profile Settings **/
export const PROFILE_API_URL = `${NDC_PORTAL_WS_CONTEXT}/userprofile`;

/** Reader Settings **/
export const READER_API_URL = `${NDC_PORTAL_WS_CONTEXT}/reader`;

/** Publication Settings **/
export const PUBLICATION_API_URL = `${NDC_PORTAL_WS_CONTEXT}/publication`;

/** Cookie Settings **/
export const COOKIE_NAME = "dp-token";
export const COOKIE_PATH = "/";
export const COOKIE_SECURE = "true";
export const COOKIE_EXPIRES = 7;
export const COOKIE_APP_NAME = "cci-app-version";

//** Evolok Settings **/
//export const EVOLOK_API_URL = "https://ndcx.uat.evolok.net/ic/api";
export const EVOLOK_API_URL = `${process.env.REACT_APP_EVOLOK_API}`;
export const COOKIE_EVOLOK_SESSIONID = "ev_ss";

/** Component Urls  **/
//export const BASE_URL =  "/ndc-portal/";
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const HOME_URL = `${BASE_URL}`;

export const LOGIN_URL = `${BASE_URL}login`;
export const PROFILE_URL = `${BASE_URL}profiel`;


/** Error messages **/
export const INVALID_LOGIN = "Gebruikersnaam en/of wachtwoord onjuist";
export const ACTIVATE_MAIL = "U ontvangt een mail waarmee u uw account kan activeren";
export const ACCOUNT_ACTIVATED = "Uw account is geactiveerd. U kan nu inloggen"
export const PASSWORD_RESET = "Uw wachtwoord is gereset. Probeer in te loggen met uw nieuwe wachtwoord"
export const NO_LOGON = "U moet ingelogd zijn om deze publicatie te lezen";
export const NO_SUBSCRIPTION = "U heeft niet het juiste abonnement om deze publicatie te lezen";
export const NO_PREMIUM_LEFT = "U bent door uw gratis premium publicaties heen. Neem een abonnement om verder te lezen";