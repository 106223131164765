import React, { Component } from "react";
import PublicationUtil from "../util/PublicationUtil.js";
import CookieWallService from "../../api/CookieWallService.js";

// Component for creating footer
class FooterComponent extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="row">
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/"
                >
                  Mediahuis Noord
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/adverteren/"
                >
                  Adverteren
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.familieberichten.nl"
                >
                  Familieberichten
                </a>
              </li>
            </ul>
          </div>
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/algemene-gebruikersvoorwaarden-uitgaven/"
                >
                  Algemene voorwaarden
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/privacy/"
                >
                  Privacy Statement
                </a>
              </li>
              <li>
                <button onClick={() => CookieWallService.openPreferences()}>
                  Privacy voorkeuren
                </button>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/cookieverklaring_tcf2-0/"
                >
                  Cookieverklaring
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/disclaimer/"
                >
                  Disclaimer
                </a>
              </li>
            </ul>
          </div>
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  id="archief_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.dekrantvantoen.nl"
                >
                  Archief
                </a>
              </li>
              <li>
                <a
                  id="bezorging_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuisnoord.nl/krantgemist"
                >
                  Bezorging
                </a>
              </li>
              <li>
                <a
                  id="contact_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${PublicationUtil.getPublicationName()}.nl/redactie-en-contact`}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;
