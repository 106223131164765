import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PublicationUtil from "../util/PublicationUtil";

// import AuthenticationService from '../../api/AuthenticationService.js';
// import {HOME_URL} from '../../constants';

// Component for creating Header
class HeaderComponent extends Component {
  // Mobile Menu and Authentication are not activated

  constructor(props) {
    super(props);
    /*
        this.state = {
            checked: false,
        }

       this.logout = this.logout.bind(this);
       this.handleLink = this.handleLink.bind(this);
       this.toggleMobileMenu = this.toggleMobileMenu.bind(this);

       */
    const publicationName = PublicationUtil.getPublicationName();
    if (publicationName) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = `${PublicationUtil.getHostName()}/favicon/${publicationName}.png`;
    }
  }

  render() {
    //const isUserLoggedIn = AuthenticationService.isUserLoggedIn();

    return (
      <header>
        <nav id="main-nav" className="main-nav">
          {/* Mobile Menu
                    {this.props.location.pathname !== LOGIN_URL && 
                        <>  
                            <input id="navi-toggle" type="checkbox" className="main-nav__checkbox" checked={this.state.checked} onChange={this.toggleMobileMenu} />
                            <label htmlFor="navi-toggle" className="main-nav__button">
                                <span className="main-nav__icon">&nbsp;</span>
                            </label>
                        </>
                    }
                    <div className="main-nav__background">&nbsp;</div>
                    */}

          <div className="main-nav__list">
            <ul className="main-nav__list__left">
              {/*        {isUserLoggedIn && <li><NavLink to={HOME_URL} onClick={this.handleLink} className={this.props.location.pathname === HOME_URL ? "selected" : null}>Overzicht</NavLink></li>} */}
            </ul>
            <ul className="main-nav__list__right">
              {/*        {isUserLoggedIn &&  <li><button onClick={this.logout}>Uitloggen</button></li>} */}
            </ul>
          </div>
        </nav>
      </header>
    );
  }

  /* Mobile menu is not activated
    handleLink(){  
        this.setState({
            checked: !this.state.checked ? true : false,
        })
        var mainSection = document.getElementById("main-section");

        mainSection.classList.remove("mobile-menu-display-none")
    }

    toggleMobileMenu(){
        this.setState({
            checked: !this.state.checked ? true : false
        });

        var mainSection = document.getElementById("main-section");

        if(!this.state.checked){
            mainSection.classList.add("mobile-menu-display-none")
        }
        
        if(this.state.checked){
           mainSection.classList.remove("mobile-menu-display-none")
        }
    } 

    */

  /* Authentication functionality is not activated
    logout(){
        this.handleLink();
        AuthenticationService.logout(this.props);
    }
    */
}

export default withRouter(HeaderComponent);
