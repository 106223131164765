import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import 'moment/locale/nl';

import ArticlesService from '../../../api/ArticlesService.js';

class Task extends React.Component {
	constructor(props) {
		super(props);
			this.state = {
			imageUrl: this.props.article.imageUrl,
			title: this.props.article.title,
			section: this.props.article.section,
			link: this.props.article.link
		}
  	}

	addDomain(articleLink) {
		try {
			var domain = this.props.articleDomain

			return (domain + articleLink)
		} catch (e) {
			return (articleLink)
		}
	}

	render() {
		return (
			<a className="article-link" target="_blank" rel="noopener noreferrer" href={this.addDomain(this.state.link)}>
				<div className="container">
					<img src={this.state.imageUrl} alt="thumbnail"/>
					<p className="article-link">
						{this.state.title} 
					</p>
				</div>
			</a> 
		);
	}
}

class PublicationArticleSectionComponent extends Component{
	constructor(props){
		super(props);
		this.state = {
			errorArticles: false,
			articleList: [],
			articleListRegion: [],
			articleListNewspaper: []
		}
	}

	componentDidMount(){
		this.loadArticles()
	}

	render (){
		return ( 
		<section className="publication-articles">
			<div className="publication-articles__container">
				{this.state.articleList.map(d => {
					return <div key={d.id}> <Task article={d} articleDomain={this.props.articleDomain} recentArticlesLinkRegion={this.props.recentArticlesLinkRegion} /><br></br></div>;
				})}
			</div>
			<p>&nbsp;</p>
			<p><a className="article-link" target="_blank" rel="noopener noreferrer" href={this.props.regionPageLink}>Lees meer artikelen</a></p>
			<p>&nbsp;</p>
		</section>   
		)
	}

	loadArticles(){  
		const getDataNewspaper = async () => {
			if (this.props.recentArticlesLinkNewspaper === null || this.props.recentArticlesLinkNewspaper.trim() === '') {
				const data = (await ArticlesService.getArticles(this.props.recentArticlesLinkRegion)).data;
				this.setState({articleList : data.data})
			}
			else {
				const data = (await ArticlesService.getArticles(this.props.recentArticlesLinkRegion)).data;
				this.setState({articleListRegion : data.data})

				const dataNewspaper = (await ArticlesService.getArticles(this.props.recentArticlesLinkNewspaper)).data;
				this.setState({articleListNewspaper : dataNewspaper.data})

				this.setState({articleList : (this.state.articleListNewspaper.concat(this.state.articleListRegion).sort(() => Math.random() - 0.2))})
			}
		}
		
		getDataNewspaper();
	}
}

export default withRouter(PublicationArticleSectionComponent);