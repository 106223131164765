import React, {Component} from 'react';
import { DidomiSDK } from '@didomi/react';

import CookieWallService from '../../api/CookieWallService';

class CookieWall extends Component {

    onCookieWallReady(cookieWall) {
        CookieWallService.setCookieWallObject(cookieWall);
    }

    onConsentChanged(cwtToken) {
        console.log(cwtToken);
        CookieWallService.consentChanged(cwtToken);
    }
    
    render(){
        return (
            <DidomiSDK
                apiKey="11ef8ac9-6270-4d5e-8b99-8d6a5bd60059"
                iabVersion={2}
                gdprAppliesGlobally={true}
                onReady={this.onCookieWallReady.bind(this)}
                onConsentChanged={this.onConsentChanged.bind(this)}
                onNoticeShown={() => console.log("Cookie instellingen tonen")}
                onNoticeHidden={() => console.log("Cookie instellingen verbergen")}
            />
        )
    }

}

export default CookieWall;