import React from 'react';

// generate the error component
function ErrorComponent() {
    return (
            <section className="errorpage">
                <h1 className="heading-primary">404</h1>
                <p>De aangevraagde pagina is niet gevonden</p>
            </section>
        )
}

export default ErrorComponent;